.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  background-color: rgb(39, 105, 123);
  color: white;
  width: 100%;
}
@media (max-width: 767px) {
  .header-container {
    flex-direction: column;
    gap: 0px;
  }
}

.header-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 10px;
}

.header-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 300px;
  color: white;
  padding-top: 12px;
  padding-bottom: 5px;
}
@media (max-width: 767px) {
  .header-selector {
    flex-direction: column;
  }

  .header-logo {
    width: 100%;
    gap: 25%;
  }
}

.header-selector .MuiFormControl-root {
  color: white;
}

.header-selector .MuiInputBase-input {
  color: white;
}

.header-selector .MuiFormLabel-root {
  color: white;
}

.header-selector .MuiOutlinedInput-notchedOutline {
  border-color: white;
}
