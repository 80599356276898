.studentFeeChartContainer {
  display: flex;
  /* border: 1px solid red; */
  gap: 10px;
}
.feeDetailsCard {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
#fee-type-select {
  min-width: 165px;
}
