.pill {
  border-radius: 10px;
  margin: 0 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.7);
}

.add-attendance {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.add-attendance .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row:last-child {
  margin-bottom: 0;
}

.attendance-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.half {
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 10px;
}

.half:first-child {
  border-bottom-left-radius: 10px;
  border-right: 1px solid black;
}
.half:last-child {
  border-bottom-right-radius: 10px;
}

.student-info {
  padding: 10px;
  border-bottom: 1px solid black;
}

.add-attendance button {
  display: block;
  margin: 5px 0;
  padding: 10px;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.add-attendance button:hover {
  background-color: #45a049;
}

.add-attendance button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}