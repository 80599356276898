.additional-rating_student-list-root {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 10px;
  gap: 20px;
}

#additional-rating_student-list-item {
  padding: 10px;
  border-radius: 10px;
  background-color: #f1f1f1;
}
.additional-rating_slider-title-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.additional-rating_all-slider-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.additional-rating_slider-container {
  
  width: 100%;
}
#additional-rating_form-control-container {
  width: calc(100% - 20px);
  margin: 10px;
}
#additional-rating_form-control {
  width: 100%;
}

.additional-rating button {
  display: block;
  margin: 5px 0;
  padding: 10px;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 100%;
}