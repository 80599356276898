.day-rating_student-list-root {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 10px;
  gap: 20px;
}
.day-rating_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}
.day-rating_header-legend {
  display: flex;
  width: 40%;
  flex-direction: column;
  height: 50px;
  padding: 10px;
  border: 1px solid black;
  background-color: #f1f1f1;
  border-radius: 10px;
}
#day-rating_student-list_item {
  padding: 10px;
  /* border: 1px solid black; */
  border-radius: 10px;
  background-color: #f1f1f1;
}
#day-rating_student-list_item-header {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}
.day-rating_slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.submit-day-rating  {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}