.show-attendance-container {
  width: 100%; /* Match the parent container */
  margin: 0 auto; /* Center within the parent */
  box-sizing: border-box;
}

.react-calendar {
  width: 100%; /* Allow the calendar to fit its parent */
  max-width: 100%; /* Prevent it from exceeding the parent's width */
  overflow: hidden; /* Prevent the calendar from forcing overflow */
  box-sizing: border-box; /* Include padding and borders in width calculations */
  margin: 0 auto; /* Center the calendar horizontally */
}

.react-calendar * {
  max-width: 100%; /* Ensure no internal element exceeds its container */
}

.react-calendar .react-calendar__tile {
  text-align: center; /* Ensure tiles are well-aligned */
  word-wrap: break-word; /* Prevent text from causing overflow */
}

.react-calendar .present {
  background-color: green !important;
  color: white;
  border-radius: 20%;
}

.react-calendar .absent {
  background-color: red !important;
  color: white;
  border-radius: 20%;
}

.react-calendar__month-view__weekdays,
.react-calendar__month-view__days {
  display: grid !important; /* Force grid layout */
  grid-template-columns: repeat(7, 1fr); /* Ensure consistent column sizes */
}

.react-calendar__tile {
  width: 100% !important; /* Ensure tiles don't overflow */
  max-width: 100% !important;
}
