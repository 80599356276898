.register-header {
  display: flex;
  align-items: center; /* Vertically center all items */
  justify-content: space-between;
  background-color: rgb(39, 105, 123);
  border-radius: 10px;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* margin: 20px 40px; */
  padding: 0 20px;
  height: 60px; /* Fixed height for the header */
}

/* .register-header h1 {
  margin: 0;
  line-height: 60px;
} */

.register-header img {
  height: 433%;
  max-width: 40%;
}

/* Assuming your Logout component renders an element with a class or you add one.
   For example, if you wrap its content in a <div className="logout">: */
/* .register-header .logout {
  height: 100%;
  display: flex;
  align-items: center;
} */
