.header-homepage {
  display: flex;
  justify-content: space-between;
  background-color: rgb(39, 105, 123);
  border-radius: 20px;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: 20px 40px;
  padding: 0 20px;
}
