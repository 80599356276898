.chat-container {
  /* display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
  max-width: 500px;
  border: 1px solid #ccc;
  border-radius: 8px; */
  overflow: hidden;
  background: #f5f5f5;
}

.chat-box {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

/* Chat bubble styles */
.chat-bubble {
  max-width: 75%;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 8px;
  font-size: 14px;
}

.sent {
  align-self: flex-end;
  background-color: #007aff;
  color: white;
}

.received {
  align-self: flex-start;
  background-color: #e5e5ea;
  color: black;
}

/* Meta info (name, time) */
.remark-meta {
  font-size: 10px;
  margin-top: 4px;
  display: block;
  opacity: 0.7;
}

/* Input section */
.chat-input {
  display: flex;
  align-items: center;
  padding: 8px;
  background: white;
  border-top: 1px solid #ccc;
}
