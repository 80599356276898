.superuserhome-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 50px;
}

.superuserhome-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Desktop devices */
@media (min-width: 768px) {
  .superuserhome-card {
    flex: 1 0 20%; /* Will take up 20% of the row, so max 4 items per row */
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .superuserhome-card {
    flex: 1 0 50%; /* Will take up 50% of the row, so max 2 items per row */
  }
}

/* Different background and text colors for each card */
.superuserhome-card:nth-child(1) {
  background-color: #ff0000;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
} /* Black text for red background */
.superuserhome-card:nth-child(2) {
  background-color: #00ff00;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
} /* Black text for green background */
.superuserhome-card:nth-child(3) {
  background-color: #0000ff;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
} /* White text for blue background */
.superuserhome-card:nth-child(4) {
  background-color: #ffff00;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
} /* Black text for yellow background */
.superuserhome-card:nth-child(5) {
  background-color: #ff00ff;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
} /* White text for magenta background */
.superuserhome-card:nth-child(6) {
  background-color: #00ffff;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
} /* Black text for cyan background */
.superuserhome-card:nth-child(7) {
  background-color: #6b3add;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
} /* Black text for light gray background */
.superuserhome-card:nth-child(8) {
  background-color: maroon;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
} /* White text for light maroon background */
.superuserhome-card:nth-child(9) {
  background-color: rgb(255, 149, 0);
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
} /* White text for light maroon background */
.superuserhome-card:nth-child(10) {
  background-color: #4e4e4e;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
} /* Black text for light gray background */
.superuserhome-card:nth-child(11) {
  background-color: #f0f0f0;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
} /* Black text for light gray background */
