.student-table-2_parent {
  /* overflow-x: hidden; */
}
.student-table-2_parent .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  background-color: rgb(39, 105, 123);
  color: white;
  width: 100%;
}
@media (max-width: 767px) {
  .student-table-2_parent .header-container {
    flex-direction: column;
    gap: 0px;
  }
}

.student-table-2_parent .header-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 300px;
  color: white;
  padding-top: 12px;
  padding-bottom: 5px;
}
@media (max-width: 767px) {
  .student-table-2_parent .header-selector {
    flex-direction: column;
  }
}

.header-selector .MuiFormControl-root {
  color: white;
}

.header-selector .MuiInputBase-input {
  color: white;
}

.header-selector .MuiFormLabel-root {
  color: white;
}

.header-selector .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.student-table-2_parent .chartsInRow {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 10px;
}
@media (max-width: 767px) {
  .student-table-2_parent .chartsInRow {
    flex-direction: column;
  }
}

.student-table-2_parent .chartContainer {
  width: 500px;
  /* height: 60%; */
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* margin: 20px; */
}
@media (max-width: 767px) {
  .student-table-2_parent .chartContainer {
    width: 300px;
    padding: 0;
    margin: 0;
  }
  .MuiTableCell-body {
    padding: 16px 0px !important;
  }
}

#periodSelect {
  display: flex;
}

.student-details-title {
  display: flex;
  justify-content: space-between;
}
.sticky-header {
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: lightblue;
}
.sticky-row {
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: yellow;
}
.MuiTableContainer-root {
  overflow-x: visible !important;
}
